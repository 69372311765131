<swx-dialog>
    <swx-dialog-header>
        {{title}}
    </swx-dialog-header>
    <swx-dialog-content>
        @if(!html) {
            <pre>{{rawData}}</pre>
        }
        @if(html) {
            <div style="width: calc(100% - 20px); height: 620px; overflow: auto; word-wrap: break-word;" [innerHTML]="html"></div>
        }
    </swx-dialog-content>
</swx-dialog>
