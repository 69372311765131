import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { BusyIndicatorService, SwxModule } from "swx.front-end-lib";
import { RootScope } from "../shared/RootScope.service";
import { DashboardServiceComponent } from "./DashboardService.component";
import { NgIf, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    templateUrl: './Dashboard.component.html',
    styleUrls: ['./Dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        DashboardServiceComponent,
        NgFor,
    ],
})
export class DashboardComponent implements OnInit, OnDestroy {
    Math = Math;
    Object = Object;
    refreshInterval: any;
    serviceMonitorStatus: any;
    config: any;
    query: any;
    datePickerOptions: DateTimePickerOptions;

    constructor(
        public $root: RootScope,
        public api: ApiService,
        public busyIndicator: BusyIndicatorService,
    ) {
    }

    ngOnInit(): void {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage['serviceStatusQuery']) {
            this.query = JSON.parse(window.localStorage['serviceStatusQuery']);
        } else {
            this.resetQuery();
        }

        this.updateRefreshInterval();
        this.refresh();
    }

    resetQuery() {
        this.query = {
            RefreshInterval: 60,
        };
    }

    updateRefreshInterval() {
        clearInterval(this.refreshInterval);
        this.refreshInterval = setInterval(() => this.refresh(), (this.query.RefreshInterval) * 1000);
    }

    refresh() {
        window.localStorage['serviceStatusQuery'] = JSON.stringify(this.query);

        this.api.ServiceMonitorStatus.queryObject().$promise.then(result => this.serviceMonitorStatus = result);
    }
    
    ngOnDestroy(): void {
        clearInterval(this.refreshInterval);
    }
}
